<template>
	<main class="main">
		<section class="container">
			<Crumbs :data="crumbs"></Crumbs>
			<TopBlock :data="page.block1"></TopBlock>
		</section>

		<section class="main__full-box main__gradient flex flex--middle">
			<img :src="page.block2.image" alt="EКАРТА" class="main__full-box-image" />
			<div class="main__full-box-content">
				<h2 class="main__title">{{ page.block2.title }}</h2>
				<div class="editor" v-html="page.block2.content"></div>
			</div>
		</section>
		<section class="container">
			<div class="main__box">
				<h2 class="main__title main__title--center">
					{{ page.block4.title }}
				</h2>
				<div
					class="main__text main__text--center main__text--max-width editor"
					v-html="page.block4.content"
				></div>
				<Advantages :data="page.block5"></Advantages>
			</div>
		</section>
		<!--
		<section class="main__full-box main__gradient">
			<div class="flex flex--middle">
				<img
					:src="require('@/assets/images/background/bg_ekarta.png')"
					alt="Е-карта"
					class="main__full-box-image"
				/>
				<div class="main__full-box-content">
					<h2 class="main__title">
						E-карта
					</h2>
					<p class="main__text">
						ЕСК выпускается на базе национальной платежной системы «МИР» и
						является полноценной банковской картой с бесплатным обслуживанием и
						технологией бесконтактной оплаты. Используя Единую социальную карту,
						граждане получают доступ ко всем программам лояльности
						банка-партнера и национальной платежной системы. Со стороны банков
						проводится бесплатное оформление карты и обслуживание карты,
						открытие расчетного счета.
					</p>
					<p class="main__text">
						Со списком отделений банков-партнеров, оформляющих Карту, можно
						ознакомиться на информационном портале и на
						<a href="#" @click.prevent class="link link--light"
							>сайтах партнеров проекта</a
						>.
					</p>
				</div>
			</div>
		</section>
-->
		<!--
		<section class="container">
			<div class="main__box">
				<h2 class="main__title main__title--center">
					Преимущества Единой социальной карты
				</h2>
				<p class="main__text main__text--center main__text--max-width">
					С помощью карты гражданин Свердловской области сможет оплатить товары
					и услуги, проезд в общественном транспорте, а также получать скидки и
					бонусы от партнеров программы.
				</p>
				<Advantages :data="advantages"></Advantages>
			</div>
		</section>
-->
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs.vue'
import TopBlock from '@/components/TopBlock.vue'
import Advantages from '@/components/Advantages.vue'
import Axios from 'axios'
export default {
	name: 'TransportApplication',

	components: {
		Crumbs,
		TopBlock,
		Advantages,
	},

	data() {
		return {
			crumbs: null,
			data: null,
			advantages: null,
			page: null,
		}
	},

	mounted() {


		let icons = [
			{
				icon: require('@/assets/images/icons/icon_lock-blue.svg'),
			},
			{
				icon: require('@/assets/images/icons/icon_credit-card-blue.svg'),
			},
			{
				icon: require('@/assets/images/icons/icon_check-blue.svg'),
			},
			{
				icon: require('@/assets/images/icons/icon_award-blue.svg'),
			},
		]
		let self = this
		let query = {
			page: this.$route.fullPath,
			block: this.$options.name,
		}
		let alias = query.page.split('/')
		alias = alias[alias.length - 1]

		Axios({
			url: `${process.env.VUE_APP_API_VERSION}/page/${alias}`,
			method: 'GET',
		})
		.then(response => {
			self.page = response.data
			self.page.block1.animationBg = require('@/assets/images/animation/animation_circle-pattern.svg')
			self.page.block1.animationFront = require('@/assets/images/icons/icon_transport.svg')
			self.page.block1.animationClassBg = 'animation-pattern'
			self.page.block1.animationClassFront = 'animation-icon'
			self.page.block1.listClass = 'editor--list-column-2'
			self.page.block5.forEach(function(item, i) {
				self.page.block5[i].icon = icons[i].icon
			})
		})
		.catch(err => {
			console.log(err)
		})

		this.crumbs = [
			{
				link: 'Capabilities',
				text: 'Возможности',
			},
			{
				link: null,
				text: 'Транспортное приложение',
			},
		]
		/*
		this.data = {
			title: 'Транспортное приложение',
			text:
				'ЕСК может использоваться в качестве электронного проездного билета. На карту можно перенести уже существующий проездной документ, в том числе льготный, студенческий, школьный, пенсионный, либо оформить новый. Количество возможных оформляемых проездных не будет ограничено.',
			subTitle: 'В Карту будут включены все виды проездных билетов',
			list: {
				class: 'main__item--blue',
				data: [
					'По наземным маршрутам',
					'Пригородных электропоездах Свердловской области',
					'В метрополитене',
				],
			},
			animationBg: require('@/assets/images/animation/animation_circle-pattern.svg'),
			animationFront: require('@/assets/images/icons/icon_transport.svg'),
			animationClassBg: 'animation-pattern',
			animationClassFront: 'animation-icon',
		}

		this.advantages = [
			{
				title: 'Надежная защита информации в соответствии с ГОСТом',
				text:
					'Ваши персональные данные надежно зашифрованы на карте в электронном виде. При этом, на самой карте отсутствует личная информация, что гарантирует дополнительную безопасность.',
				icon: require('@/assets/images/icons/icon_lock-blue.svg'),
			},
			{
				title: 'Уникальный электронный ключ к городской инфраструктуре',
				text:
					'ЕСК - это многофункциональная пластиковая карта с электронным носителем, с помощью которой вы можете оплатить товары и услуги, проезд в общественном транспорте, получать бонусы.',
				icon: require('@/assets/images/icons/icon_credit-card-blue.svg'),
			},
			{
				title: 'Мгновенная выдача',
				text:
					'Единую социальную карту Свердловской области можно оформить в отделениях банков-партнеров, а также в отделениях МФЦ. Для этого достаточно посетить отделение один раз. Готовую карту вы получите уже через 15 минут.',
				icon: require('@/assets/images/icons/icon_check-blue.svg'),
			},
			{
				title: 'Бесплатно',
				text:
					'Оформить Единую социальную карту и бесплатно пользоваться всеми сервисами может каждый житель Свердловской области в возрасте от 14 лет. Музеи, театры, кино, спорт, путешествия и многое другое – на особых условиях и благодаря одной карте.',
				icon: require('@/assets/images/icons/icon_award-blue.svg'),
			},
		]
		*/
	},
}
</script>
